<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <div class="d-flex align-items-center">
        <label class="inline-label">Show</label>
        <b-form-select v-model="perPage" :options="perPageOptions" @change="handlePerPage" />
      </div>

      <div class="d-flex">
        <b-button variant="danger" @click="resetSearch()" class="ml-1">
          <feather-icon icon="RefreshCcwIcon" />
        </b-button>

        <search :add-button="true" @addButtonAction="$router.push({ name: 'user/form' })"
          @handleAdvanceSearch="handleSearch" @resetAdvanceSearch="handleSearchReset" />
      </div>
    </div>

    <b-table class="border mt-1" :fields="tableField" :items="tableData" show-empty responsive hover :busy="loading">
      <template #table-busy>
        <div class="text-center text-danger my-2" style="padding:50px 0">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(userName)="data">
        <span class="text-nowrap">{{ (data.value) }}</span>
      </template>

      <template #cell(action)="data">
        <b-button-group size="sm">
          <b-button variant="flat-dark" @click.prevent="openModalEdit(data.item.kodeuser)" v-b-tooltip.hover
            title="Edit">
            <feather-icon size="20" icon="EditIcon" />
          </b-button>

          <b-button variant="flat-dark" @click.prevent="changePassword(data.item.kodeuser)" v-b-tooltip.hover
            title="Change Password">
            <feather-icon size="20" icon="KeyIcon" />
          </b-button>
        </b-button-group>
      </template>

      <template #empty="scope">
        <div class="text-center my-75">
          <b-img :src="ClipboardsSecondary" />
          <div class="text-secondary font-small-3 mt-75">
            Tidak ada data
          </div>
        </div>
      </template>
    </b-table>

    <b-modal id="modal-reset-password" title="Reset Password" :no-close-on-backdrop="true" hide-footer
      v-model="isShowModalReset">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label="New Password" label-for="password-input" invalid-feedback="Password is required">
          <b-input-group>
            <b-form-input id="password-input" type="password" :type="passwordFieldType" v-model="password" required
              placeholder="Masukkan Password Anda"></b-form-input>
            <b-input-group-append>
              <b-button :variant="passwordFieldType === 'password' ? 'outline-dark' : 'outline-primary'"
                @click="togglePasswordVisibility('password')">
                <feather-icon :icon="passwordToggleIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Confirm Password" label-for="password-input1" invalid-feedback="Password is required">
          <b-input-group>
            <b-form-input id="password-input1" type="password" :type="password1FieldType" v-model="password1" required
              placeholder="Masukkan Konfirmasi Password Anda"></b-form-input>
            <b-input-group-append>
              <b-button :variant="password1FieldType === 'password' ? 'outline-dark' : 'outline-primary'"
                @click="togglePassword1Visibility('password')">
                <feather-icon :icon="password1ToggleIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </form>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="dark" @click="handleTutup">Cancel</b-button>
        <b-button variant="success" @click="handleReset(selectedData)">Update</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-edit-user" title="Edit User" :no-close-on-backdrop="true" hide-footer v-model="isShowModalEdit">
      <form ref="form" @submit.stop.prevent="handleSubmitEdit">
        <b-form-group label="Username" label-for="username" invalid-feedback="Username is required">
          <b-form-input id="username" type="text" v-model="username" required
            placeholder="Masukkan Username Anda"></b-form-input>
        </b-form-group>
      </form>

      <div class="d-flex justify-content-end mt-2">
        <b-button class="mr-1" variant="dark" @click="handleTutup">Cancel</b-button>
        <b-button variant="success" @click="handleEdit(selectedData)">Update</b-button>
      </div>
    </b-modal>

    <div class="d-flex justify-content-between flex-wrap">
      <label class="inline-label">Count : {{ tableData.length }} {{ tableData.length > 1 ? 'Items' : 'Item' }}</label>
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
    </div>
  </b-card>
</template>

<script>
import { BFormSelect, BInputGroup, BButton, BTable, BFormCheckbox } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import Search from './Search.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormSelect,
    Search,
    BInputGroup,
    BButton,
    BTable,
    BFormCheckbox
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ClipboardsSecondary,
      searchVal: '',
      rows: 0,
      perPage: 10,
      currentPage: 1,
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      tableField: [
        { key: 'no', label: 'No.', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
        { key: 'username', label: 'Username', thClass: 'text-center' },
        { key: 'action', label: 'Action', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
      ],
      tableData: [],
      loading: false,
      username: '',
      password: '',
      password1: '',
      selectedUser: null,
      isShowModalReset: false,
      isShowModalEdit: false,
      selectedData: null,
      selectedNamaData: null,
      passwordFieldType: 'password',
      password1FieldType: 'password',
    }
  },
  watch: {
    currentPage(x, y) {
      if (x !== y) {
        this.getListData()
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon';
    },

    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon';
    },
  },
  mounted() {
    this.getListData()
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },

    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password';
    },

    handleSearch(val) {
      this.searchVal = val
      this.getListData()
    },

    resetSearch() {
      this.searchVal = ''
      this.getListData()
    },

    handleSearchReset(val) {
      this.searchVal = val
      this.getListData();
    },

    handlePerPage(val) {
      this.perPage = val
      this.getListData()
    },

    getListData() {
      this.loading = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        keyword: this.searchVal.Keyword,
      };

      this.$http.get('/api/user/list', { params: params }).then(async res => {
        this.loading = false;
        this.tableData = res.data.data;
        this.rows = res.data.maxPage * this.perPage;

        if (this.searchVal != '') {
          if (res.data.data.length > 0) {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Pencarian data berhasil ditemukan',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                variant: 'warning',
                text: 'Pencarian data tidak ditemukan',
              },
            });
          }
        }
      }).catch(e => {
        this.loading = false;
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
        this.tableData = [];
        this.rows = 0;
      });
    },

    openModalEdit(kodeuser) {
      this.selectedData = kodeuser
      this.isShowModalEdit = true;
    },

    handleTutup() {
      this.isShowModalReset = false;
      this.isShowModalEdit = false;
    },

    changePassword(kodeuser) {
      this.selectedData = kodeuser
      this.isShowModalReset = true;
    },

    resetModalResetPassword() {
      this.password = ''
      this.password1 = ''
    },

    resetPasswordHandleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    editHandleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmitEdit()
    },

    handleReset(kodeuser) {
      if (!this.validateFormResetPassword()) {
        return
      }

      this.$http.post('/api/user/changepassword', { kodeuser: kodeuser, login: this.password }).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              variant: 'danger',
              text: res.data.message ?? 'Gagal mengubah password pengguna, silahkan hubungi admin',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Berhasil mengubah password pengguna',
            },
          });
          this.isShowModalReset = false
          this.password = ''
          this.password1 = ''
          this.getListData()
        }
      }).catch(e => {
        this.loading = false
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mengubah password pengguna, silahkan hubungi admin',
          },
        });
        this.loading = false
      })
      this.isShowModalReset = false;
    },

    handleEdit(kodeuser) {
      if (!this.username === '' || !this.username === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi username',
          },
        });
        return false
      }

      const data = {
        kodeuser: kodeuser,
        username: this.username
      }

      this.$http.post('/api/user/edit', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              variant: 'danger',
              text: res.data.message ?? 'Gagal mengubah username pengguna, silahkan hubungi admin',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Berhasil mengubah username pengguna',
            },
          });
          this.isShowModalReset = false
          this.password = ''
          this.password1 = ''
          this.getListData()
        }
      }).catch(e => {
        this.loading = false
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mengubah username pengguna, silahkan hubungi admin',
          },
        });
        this.loading = false
      })
      this.isShowModalEdit = false;
    },

    validateFormResetPassword() {
      if (!this.password === '' || !this.password === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi password',
          },
        });
        return false
      }

      if (!this.password.length !== 0 && !this.password1 === '' || !this.password.length !== 0 && !this.password1 === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi konfirmasi password',
          },
        });
        return false
      }

      if (!/^(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_+=])[A-Za-z0-9!@#$%^&*()-_+= ]{6,12}$/.test(this.password)) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Password harus memiliki satu huruf kapital, mengandung angka dan simbol, dengan panjang 6-12 karakter',
          },
        });
        return false;
      }

      if (this.password !== this.password1) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Konfirmasi password tidak sama dengan password yang anda masukkan',
          },
        });
        return false
      }

      return true
    }
  }
}
</script>